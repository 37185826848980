import "../ContactForm/contactform.scss";

import React, { useState } from "react";
import { Link, navigate, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import theme from "../../theme";
import axios from "axios";
import Stepper from "../Stepper";
import PhonePrefixes from "data/phonePrefixes.json";
import ReactSelect from "components/ReactSelect";

const ContactFormWrapper = styled.section`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 90px 0 50px;
  position: relative;

  ${theme.screens.smScreen} {
    text-align: center;
  }
`;

const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  color: #17204f;
  padding: 12px 15px;
  border: none;
  outline: none;
  appearance: none;
  margin-bottom: 20px;

  &::placeholder {
    font-size: 12px;
    color: #17204f;
  }
`;

const Title = styled.h2`
  color: #fff;
  font-size: 36px;
  margin-bottom: 12px;

  ${theme.screens.lgScreen} {
    font-size: 32px;
  }

  ${theme.screens.smScreen} {
    font-size: 28px;
    text-align: center;
  }
`;

const TitleHideMobile = styled(Title)`
  ${theme.screens.lgScreen} {
    display: none;
  }
`;

const TitleMobile = styled(Title)`
  display: none;
  ${theme.screens.lgScreen} {
    display: inline-block;
  }
`;

const PersonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  ${theme.screens.lgScreen} {
    margin-bottom: 80px;
  }

  ${theme.screens.smScreen} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  margin-right: 40px;
  max-width: 200px;
  height: auto;

  ${theme.screens.smScreen} {
    display: block;
    margin: 0 auto 15px;
  }
`;

const Job = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
`;

const ContactDetail = styled.a`
  color: #fff;
  font-size: 16px;
  display: block;

  ${theme.mixins.onEvent} {
    color: #fff;
    text-decoration: underline;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin: 10px 0 20px;

  ${theme.screens.smScreen} {
    justify-content: center;
  }

  a {
    margin-right: 8px;
  }
`;

const SocialIcon = styled.img`
  transition: all 0.25s ease-in-out;
  ${theme.mixins.onEvent} {
    opacity: 0.5;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Description = styled.div`
  color: #fff;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 50px;

  ${theme.screens.smScreen} {
    font-size: 16px;
    text-align: center;
  }

  br {
    ${theme.screens.xlScreen} {
      display: none;
    }
  }
`;

const SingleStep = styled.div``;

const SmallText = styled.div`
  font-size: 10px;
  color: #fff;
  margin-bottom: 10px;
  &:before {
    content: "* ";
    color: ${theme.colors.gray};
  }

  ${theme.screens.smScreen} {
    text-align: justify;
  }
`;

const StepTitle = styled.div`
  font-size: 24px;
  color: #fff;
  margin-bottom: 30px;
  font-weight: 500;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 160px;
  padding: 12px 15px;
  border: none;
  outline: none;
  appearance: none;
  border-radius: 5px;
  margin: 0 0 40px;
  font-size: 12px;

  &::placeholder {
    font-size: 12px;
    color: #17204f;
  }
`;

const PolicyLink = styled(Link)`
  color: #ffffff;
  transition: all 0.25s ease-in-out;
  text-decoration: underline;
  ${theme.mixins.onEvent} {
    color: #e4e4e4;
    text-decoration: underline;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  ${theme.screens.smScreen} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RadioContainerNoMargin = styled(RadioContainer)`
  margin-bottom: 20px;

  ${theme.screens.smScreen} {
    margin-bottom: 0;
  }
`;

const CheckboxContainer = styled.div`
  margin-bottom: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;

  ${theme.screens.smScreen} {
    flex-direction: column;
  }

  button {
    &:first-of-type {
      margin-right: 20px;

      ${theme.screens.smScreen} {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const SelectContainer = styled.div`
  min-width: 150px;
  margin-right: 15px;
`;

const CheckboxWithInput = styled.div`
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${theme.screens.smScreen} {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    min-width: 133px;
    margin: 0 30px 0 0 !important;

    ${theme.screens.smScreen} {
      margin: 20px 0 10px 0 !important;
    }
  }

  input {
    margin: 0 !important;
  }
`;

const PrefixContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ContactForm = ({
  data,
  person_variant,
  form_variant,
  color,
  sub_text,
}) => {
  const info = data.nodes[0].contactPage;

  const [currentStep, setCurrentStep] = useState(0);
  const [clientType, setClientType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [productDetails1, setProductDetails1] = useState("");
  const [productDetails2, setProductDetails2] = useState("");
  const [productDetails3, setProductDetails3] = useState("");
  const [productDetails4, setProductDetails4] = useState("");
  const [productDetails5, setProductDetails5] = useState("");
  const [productDetails6, setProductDetails6] = useState("");
  const [productDetails7, setProductDetails7] = useState("");
  const [productDetails8, setProductDetails8] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [emailInputVisible, setEmailInputVisible] = useState(false);
  const [phoneInputVisible, setPhoneInputVisible] = useState(false);
  const [whatsAppInputVisible, setWhatsAppInputVisible] = useState(false);
  const [phonePrefix, setPhonePrefix] = useState("");
  const [whatsAppPrefix, setWhatsAppPrefix] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [legal, setLegal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const steps = [
    { step: 0, onClick: (e) => setCurrentStep(0) },
    { step: 1, onClick: (e) => setCurrentStep(1) },
    { step: 2, onClick: (e) => setCurrentStep(2) },
    { step: 3, onClick: (e) => setCurrentStep(3) },
  ];

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    currentStep !== 0 && setCurrentStep(currentStep - 1);
  };

  const resetForm = () => {
    setIsSending(false);
    setCurrentStep(0);
    setClientType("");
    setFirstName("");
    setLastName("");
    setCompany("");
    setCountry("");
    setMessage("");
    setProductDetails1("");
    setProductDetails2("");
    setProductDetails3("");
    setProductDetails4("");
    setProductDetails5("");
    setProductDetails6("");
    setProductDetails7("");
    setProductDetails8("");
    setTimeframe("");
    setPhoneInputVisible(false);
    setWhatsAppInputVisible(false);
    setEmailInputVisible(false);
    setEmail("");
    setPhonePrefix("");
    setWhatsAppPrefix("");
    setWhatsApp("");
    setPhone("");
    setLegal(false);
  };

  const person_render = (person_variant) => {
    switch (person_variant) {
      case "gary":
        return {
          avatar: require("assets/images/gary-avatar.png").default,
          name: "Gary Roethenbaugh",
          workplace: "Head of Prodinity Data Pooling",
          phone: [
            {
              label: "+44 7832 697 773",
              href: "+44 7832 697 773",
            },
          ],
          email: "groethenbaugh@prodinity.com",
        };
      default:
        return {
          avatar: require("assets/images/theunis-avatar.png").default,
          name: "Theunis Viljoen",
          workplace: "Founder and CEO",
          phone: [
            {
              label: "EN: +44 7785 766 829",
              href: "+44 7785 766 829",
            },
            {
              label: "PL: +48 690 553 891",
              href: "+48 690 553 891",
            },
          ],
          email: "tviljoen@prodinity.com",
          linkedin: "https://www.linkedin.com/in/theunisviljoen/",
        };
    }
  };

  const form_id_variants = () => {
    switch (form_variant) {
      case "data-pooling":
        return "1895";
      case "cyber-security":
        return "1910";
      default:
        return "1712";
    }
  };

  const current_person = person_render(person_variant);

  const formSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);

    const formData = new FormData();

    formData.set("firstName", firstName);
    formData.set("lastName", lastName);
    formData.set("clientType", clientType);
    formData.set("company", company);
    formData.set("country", country.label);
    formData.set("message", message);
    formData.set("productDetails1", productDetails1);
    formData.set("productDetails2", productDetails2);
    formData.set("productDetails3", productDetails3);
    formData.set("productDetails4", productDetails4);
    formData.set("productDetails5", productDetails5);
    formData.set("productDetails6", productDetails6);
    formData.set("productDetails7", productDetails7);
    formData.set("productDetails8", productDetails8);
    formData.set("timeframe", timeframe);
    formData.set("email", email);
    formData.set("phone", phone);
    formData.set("whatsApp", whatsApp);
    formData.set("whatsAppPrefix", whatsAppPrefix.label);
    formData.set("phonePrefix", phonePrefix.label);

    axios({
      method: "post",
      url: `https://wp.prodinity.com/wp-json/contact-form-7/v1/contact-forms/${form_id_variants()}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log("Submit success");
        resetForm();
        navigate("/thank-you/");
      })
      .catch((error) => {
        console.log(error);
        setIsSending(false);
      });
  };

  const countryOptions = PhonePrefixes.map((item) => {
    return {
      value: item.name,
      label: item.name,
      flag: item.flag,
    };
  });

  const telPrefixes = PhonePrefixes.map((item) => {
    return {
      value: item.dial_code,
      label: item.dial_code,
      flag: item.flag,
    };
  });

  const isFormValid = () => {
    // Check if agreement checkbox is selected. If not, there is no point in further validation.
    if (!legal) return false;

    // Check if at least one contact checkbox is selected. If not, there is no point in further validation.
    const atLeastOneSelected =
      phoneInputVisible || whatsAppInputVisible || emailInputVisible;
    if (!atLeastOneSelected) return false;

    // Check if all selected contact checkbox are valid (prefix && value are present).
    const selectedValid = validateSelected([
      { selected: phoneInputVisible, value: phone, validate: isPhoneValid },
      {
        selected: whatsAppInputVisible,
        value: whatsApp,
        validate: isWhatsAppValid,
      },
      { selected: emailInputVisible, value: email, validate: isEmailValid },
    ]);

    return legal && atLeastOneSelected && selectedValid;
  };

  const validateSelected = (fields) => {
    return fields
      .filter((field) => field.selected)
      .every((field) => field.validate());
  };

  const isPhoneValid = () => {
    return phoneInputVisible && phonePrefix !== "" && phone !== "";
  };

  const isWhatsAppValid = () => {
    return whatsAppInputVisible && whatsAppPrefix !== "" && whatsApp !== "";
  };

  const isEmailValid = () => {
    return emailInputVisible && email !== "";
  };

  const contact_bg_render = () => {
    switch (color) {
      case "blue":
        return require("assets/videos/newsletter-blue-background.mp4").default;
      case "green":
        return require("assets/videos/newsletter-green-background.mp4").default;
      default:
        return require("assets/videos/newsletter-background.mp4").default;
    }
  };

  return (
    <div className="contact-form">
      <ContactFormWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <Title>{info.title1}</Title>
              <Description>{sub_text || info.subtitle1}</Description>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <TitleHideMobile>{info.title2}</TitleHideMobile>
              <Description>{info.subtitle2}</Description>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <PersonContainer>
                <Avatar src={current_person?.avatar} alt="" />
                <div>
                  <h4 style={{ color: "#fff" }}>{current_person?.name}</h4>
                  <Job>{current_person?.workplace}</Job>
                  {current_person?.phone?.map(({ label, href }, index) => (
                    <ContactDetail key={index} href={`tel:${href}`}>
                      {label}
                    </ContactDetail>
                  ))}
                  <ContactDetail href={`mailto:${current_person?.email}`}>
                    {current_person?.email}
                  </ContactDetail>
                  {!!current_person?.linkedin && (
                    <Socials>
                      <a
                        href={current_person?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialIcon
                          src={
                            require("assets/icons/linkedin-white.svg").default
                          }
                          alt=""
                        />
                      </a>
                    </Socials>
                  )}
                </div>
              </PersonContainer>
            </div>
            <div className="col-lg-6">
              <TitleMobile>{info.title2}</TitleMobile>
              <div className="row">
                <div className="col-lg-11">
                  <Stepper currentStep={currentStep} steps={steps} />
                  <Form onSubmit={formSubmit}>
                    {currentStep === 0 && (
                      <SingleStep>
                        <StepTitle>
                          Are you a data Provider or Consumer?
                        </StepTitle>
                        <RadioContainer>
                          <label className="radio-wrapper">
                            Provider
                            <input
                              type="radio"
                              name="typeOfClient"
                              id="provider"
                              checked={clientType === "Provider"}
                              onChange={(e) => setClientType("Provider")}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                          <label className="radio-wrapper">
                            Consumer
                            <input
                              type="radio"
                              name="typeOfClient"
                              id="consumer"
                              checked={clientType === "Consumer"}
                              onChange={(e) => setClientType("Consumer")}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                          <label className="radio-wrapper">
                            Other
                            <input
                              type="radio"
                              name="typeOfClient"
                              id="other"
                              checked={clientType === "Other"}
                              onChange={(e) => setClientType("Other")}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                        </RadioContainer>
                        <Input
                          type="text"
                          placeholder="Name"
                          name="firstName"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                        <Input
                          type="text"
                          placeholder="Surname"
                          name="lastName"
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                        <Input
                          type="text"
                          placeholder="Company name"
                          name="company"
                          id="company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          required
                        />
                        <div style={{ marginBottom: 45 }}>
                          <ReactSelect
                            options={countryOptions}
                            onChange={setCountry}
                            placeholder="Country"
                            value={country}
                            name="country"
                            id="country"
                            isSearchable
                          />
                        </div>
                      </SingleStep>
                    )}
                    {currentStep === 1 && (
                      <SingleStep>
                        <StepTitle>
                          Details of Product/Service Request
                        </StepTitle>
                        <CheckboxContainer>
                          <div className="row">
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails1"
                                  id="productDetails1"
                                  type="checkbox"
                                  required
                                  checked={productDetails1 !== ""}
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails1 === ""
                                      ? setProductDetails1("Insight Solutions")
                                      : setProductDetails1("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Insight Solutions</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails2"
                                  id="productDetails2"
                                  type="checkbox"
                                  checked={productDetails2 !== ""}
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails2 === ""
                                      ? setProductDetails2(
                                          "Budgeting and Forecasting Solutions"
                                        )
                                      : setProductDetails2("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Budgeting and Forecasting Solutions</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails3"
                                  id="productDetails3"
                                  type="checkbox"
                                  required
                                  checked={productDetails3 !== ""}
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails3 === ""
                                      ? setProductDetails3(
                                          "System Implementation Services"
                                        )
                                      : setProductDetails3("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>System Implementation Services</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails4"
                                  id="productDetails4"
                                  type="checkbox"
                                  checked={productDetails4 !== ""}
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails4 === ""
                                      ? setProductDetails4(
                                          "Due Dilligence and Forensic Reviews"
                                        )
                                      : setProductDetails4("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Due Dilligence and Forensic Reviews</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails5"
                                  id="productDetails5"
                                  type="checkbox"
                                  checked={productDetails5 !== ""}
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails5 === ""
                                      ? setProductDetails5(
                                          "Reporting and Analysis Solutions"
                                        )
                                      : setProductDetails5("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Reporting and Analysis Solutions</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails6"
                                  id="productDetails6"
                                  type="checkbox"
                                  checked={productDetails6 !== ""}
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails6 === ""
                                      ? setProductDetails6(
                                          "Cyber Security Solutions"
                                        )
                                      : setProductDetails6("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Cyber Security Solutions</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails7"
                                  id="productDetails7"
                                  type="checkbox"
                                  checked={productDetails7 !== ""}
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails7 === ""
                                      ? setProductDetails7(
                                          "Data Pooling Solutions"
                                        )
                                      : setProductDetails7("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Data Pooling Solutions</span>
                              </label>
                            </div>
                            <div className="col-xl-6">
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="productDetails8"
                                  id="productDetails8"
                                  checked={productDetails8 !== ""}
                                  type="checkbox"
                                  required
                                  className="contact-form__checkbox"
                                  onChange={(e) =>
                                    productDetails8 === ""
                                      ? setProductDetails8("Other")
                                      : setProductDetails8("")
                                  }
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Other</span>
                              </label>
                            </div>
                          </div>
                        </CheckboxContainer>
                        <StepTitle>
                          What would you like specific help with
                        </StepTitle>
                        <TextArea
                          name="message"
                          id="message"
                          placeholder="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </SingleStep>
                    )}
                    {currentStep === 2 && (
                      <SingleStep>
                        <StepTitle>Timeframe of your project?</StepTitle>
                        <RadioContainerNoMargin>
                          <label className="radio-wrapper">
                            Immediate
                            <input
                              type="radio"
                              name="timeframe"
                              id="immediate"
                              onChange={(e) => setTimeframe("Immediate")}
                              checked={timeframe === "Immediate"}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                          <label className="radio-wrapper">
                            1 to 3 months
                            <input
                              type="radio"
                              name="timeframe"
                              id="1to3"
                              onChange={(e) => setTimeframe("1 to 3 months")}
                              checked={timeframe === "1 to 3 months"}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                        </RadioContainerNoMargin>
                        <RadioContainer>
                          <label className="radio-wrapper">
                            3 to 6 months
                            <input
                              type="radio"
                              name="timeframe"
                              id="3to6"
                              onChange={(e) => setTimeframe("3 to 6 months")}
                              checked={timeframe === "3 to 6 months"}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                          <label className="radio-wrapper">
                            More than 6 months
                            <input
                              type="radio"
                              name="timeframe"
                              id="moreThan6"
                              onChange={(e) =>
                                setTimeframe("More than 6 months")
                              }
                              checked={timeframe === "More than 6 months"}
                            />
                            <span className="radio-wrapper__checkmark"></span>
                          </label>
                        </RadioContainer>
                      </SingleStep>
                    )}
                    {currentStep === 3 && (
                      <SingleStep>
                        <StepTitle>
                          Preferred Contact Method (multiple select possible)
                        </StepTitle>
                        <div className="row">
                          <div className="col-12">
                            <CheckboxWithInput>
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="emailCheck"
                                  id="emailCheck"
                                  type="checkbox"
                                  className="contact-form__checkbox"
                                  onChange={() =>
                                    setEmailInputVisible(!emailInputVisible)
                                  }
                                  checked={emailInputVisible}
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>E-mail</span>
                              </label>
                              {emailInputVisible && (
                                <Input
                                  type="email"
                                  placeholder="Your email address"
                                  name="email"
                                  id="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              )}
                            </CheckboxWithInput>
                          </div>
                          <div className="col-12">
                            <CheckboxWithInput>
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="phoneCheck"
                                  id="phoneCheck"
                                  type="checkbox"
                                  className="contact-form__checkbox"
                                  onChange={() =>
                                    setPhoneInputVisible(!phoneInputVisible)
                                  }
                                  checked={phoneInputVisible}
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>Phone Call</span>
                              </label>
                              {phoneInputVisible && (
                                <PrefixContainer>
                                  <SelectContainer>
                                    <ReactSelect
                                      name="phonePrefix"
                                      id="phonePrefix"
                                      placeholder="Prefix"
                                      isSearchable
                                      options={telPrefixes}
                                      value={phonePrefix}
                                      onChange={setPhonePrefix}
                                    />
                                  </SelectContainer>
                                  <Input
                                    type="tel"
                                    placeholder="Phone"
                                    name="phone"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                  />
                                </PrefixContainer>
                              )}
                            </CheckboxWithInput>
                          </div>
                          <div className="col-12">
                            <CheckboxWithInput>
                              <label className="checkbox-wrapper checkbox-wrapper--square">
                                <input
                                  name="whatsAppCheck"
                                  id="whatsAppCheck"
                                  type="checkbox"
                                  className="contact-form__checkbox"
                                  onChange={() =>
                                    setWhatsAppInputVisible(
                                      !whatsAppInputVisible
                                    )
                                  }
                                  checked={whatsAppInputVisible}
                                />
                                <span className="checkmark checkmark--square"></span>
                                <span>WhatsApp</span>
                              </label>
                              {whatsAppInputVisible && (
                                <PrefixContainer>
                                  <SelectContainer>
                                    <ReactSelect
                                      name="whatsAppPrefix"
                                      id="whatsAppPrefix"
                                      placeholder="Prefix"
                                      isSearchable
                                      options={telPrefixes}
                                      value={whatsAppPrefix}
                                      onChange={setWhatsAppPrefix}
                                    />
                                  </SelectContainer>
                                  <Input
                                    type="tel"
                                    placeholder="Phone"
                                    name="whatsapp"
                                    id="whatsapp"
                                    value={whatsApp}
                                    onChange={(e) =>
                                      setWhatsApp(e.target.value)
                                    }
                                  />
                                </PrefixContainer>
                              )}
                            </CheckboxWithInput>
                          </div>
                          <div className="col-12">
                            <label className="checkbox-wrapper checkbox-wrapper--square checkbox-wrapper--legal">
                              <input
                                name="legal"
                                id="legal"
                                type="checkbox"
                                required
                                className="contact-form__checkbox"
                                onChange={(e) => setLegal(!legal)}
                              />
                              <span className="checkmark checkmark--square"></span>
                              <span className="checkbox-wrapper__small-text">
                                I agree to the processing of my personal data by
                                Prodinity, 134 Buckingham Palace Road, London
                                SW1W 9SA, United Kingdom, for the purpose of
                                marketing services, involving the use of
                                products and services. Information about the
                                data processing, as well as your rights
                                regarding the submitted data is available in our
                                Privacy Policy
                              </span>
                            </label>
                          </div>
                        </div>
                      </SingleStep>
                    )}
                    <ButtonsContainer>
                      {currentStep !== 0 && (
                        <button
                          onClick={prevStep}
                          type="button"
                          className="btn btn--outline btn--send btn--send--reversed"
                        >
                          Back
                        </button>
                      )}
                      {currentStep === 0 && (
                        <button
                          onClick={nextStep}
                          type="button"
                          disabled={
                            clientType === "" ||
                            firstName === "" ||
                            lastName === "" ||
                            company === ""
                          }
                          className="btn btn--outline btn--send"
                        >
                          Next
                        </button>
                      )}
                      {currentStep === 1 && (
                        <button
                          onClick={nextStep}
                          type="button"
                          disabled={
                            productDetails1 === "" &&
                            productDetails2 === "" &&
                            productDetails3 === "" &&
                            productDetails4 === "" &&
                            productDetails5 === "" &&
                            productDetails6 === ""
                          }
                          className="btn btn--outline btn--send"
                        >
                          Next
                        </button>
                      )}
                      {currentStep === 2 && (
                        <button
                          onClick={nextStep}
                          type="button"
                          disabled={timeframe === ""}
                          className="btn btn--outline btn--send"
                        >
                          Next
                        </button>
                      )}
                      {currentStep === steps.length - 1 && (
                        <button
                          type="submit"
                          className="btn btn--outline btn--send"
                          disabled={!isFormValid()}
                        >
                          {isSending ? "Sending..." : "Send"}
                        </button>
                      )}
                    </ButtonsContainer>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source src={contact_bg_render(color)} type="video/mp4" />
          </video>
        </div>
      </ContactFormWrapper>
    </div>
  );
};

export default ({ person_variant, form_variant, color, sub_text }) => (
  <StaticQuery
    query={graphql`
      {
        data: allWpPage(
          filter: { template: { templateName: { eq: "Contact" } } }
        ) {
          nodes {
            contactPage {
              addresCol1
              addresCol2
              title1
              title2
              subtitle1
              subtitle2
              privacyPolicy
            }
          }
        }
      }
    `}
    render={(data) => (
      <ContactForm
        {...data}
        person_variant={person_variant}
        form_variant={form_variant}
        color={color}
        sub_text={sub_text}
      />
    )}
  />
);
