import React from "react";
import Select from "react-select";

const ReactSelect = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  id,
  isSearchable,
}) => {
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "45px",
      height: "45px",
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: "45px",
      padding: "0 6px",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      height: "45px",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#17204F",
      fontSize: 12,
    }),
  };

  const selectOptionsFormat = ({ value, label, flag }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: 10, fontSize: 20 }}>{flag}</div>
      <div style={{ fontSize: 12 }}>{label}</div>
    </div>
  );
  return (
    <Select
      name={name}
      id={id}
      placeholder={placeholder}
      isSearchable={isSearchable}
      options={options}
      styles={selectStyles}
      formatOptionLabel={selectOptionsFormat}
      value={value}
      onChange={onChange}
    />
  );
};

export default ReactSelect;
