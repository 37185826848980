import React from "react";
import styled from "styled-components";
import theme from "theme";

const StepperContainer = styled.div`
  width: 100%;
  max-width: 500px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ${theme.screens.smScreen} {
    width: 100%;
    max-width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0, -50%);
    width: 98%;
    height: 2px;
    background-color: #fff;
    display: none;

    ${theme.screens.smScreen} {
      display: block;
    }
  }
`;

const SingleStep = styled.button`
  width: 20px;
  height: 20px;
  background-color: ${({ current, isCompleted }) =>
    current || isCompleted ? "#fff" : "transparent"};
  border: 2px solid #fff;
  border-radius: 50%;
  transform: scale(0.8);
  position: relative;
  cursor: pointer;

  ${theme.screens.smScreen} {
    background-color: ${({ current, isCompleted }) =>
      current || isCompleted ? "#fff" : theme.colors.pink};
  }

  &:after {
    content: "";
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translate(0, -50%);
    width: 180px;
    height: 2px;
    background-color: #fff;

    ${theme.screens.smScreen} {
      display: none;
    }
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;

const Stepper = ({ currentStep, steps }) => {
  return (
    <StepperContainer>
      {steps.map((item, index) => (
        <SingleStep
          type="button"
          key={index}
          current={currentStep === index}
          isCompleted={index < currentStep}
          onClick={index < currentStep ? item.onClick : undefined}
        />
      ))}
    </StepperContainer>
  );
};

export default Stepper;
